<template>
  <div v-if="location" class="subtitle is-size-7 record-detail-snapshot columns is-multiline">
    <div v-if="record.bio && sponsored" class="column is-12 has-text-weight-light">
      <span v-html="bio" />
    </div>
    <div v-if="sponsored" class="column is-6 is-flex is-flex-direction-column pb-0">
      <div v-if="location.address" class="icon-text">
        <span class="icon">
          <fa icon="map-marker" />
        </span>
        <span v-html="location.address.toUpperCase()" />
      </div>
      <div class="has-location-subtext">
        <span v-if="location.city">
          {{ location.city.toUpperCase() }}
        </span>
        <span v-if="location.city && location.state">
          ,
        </span>
        <span v-if="location.state">
          {{ location.state.toUpperCase() }}
        </span>
        <span v-if="location.zip">
          {{ location.zip }}
        </span>
      </div>
    </div>
    <div v-if="sponsored" class="column is-6 is-flex is-flex-direction-column">
      <p v-if="(location.phone || record.phone)" class="icon-text">
        <span class="icon">
          <fa icon="phone" />
        </span>
        <span>{{ location.phone || record.phone }}</span>
      </p>
      <p v-if="website" class="icon-text">
        <span class="icon">
          <fa icon="globe" />
        </span>
        <span>{{ websiteUrl }}</span>
      </p>
      <p v-if="(location.email || record.email) && sponsored" class="icon-text">
        <span class="icon">
          <fa icon="envelope" />
        </span>
        <span>{{ location.email || record.email }}</span>
      </p>
    </div>

    <div
      v-if="(location.phone || record.phone) && sponsored === false"
      class="column is-narrow is-flex py-0"
    >
      <p class="icon-text">
        <span class="icon">
          <fa icon="phone" />
        </span>
        <span>{{ location.phone || record.phone }}</span>
      </p>
    </div>
    <div
      v-if="website && sponsored === false"
      class="column is-narrow is-flex py-0"
    >
      <p class="icon-text">
        <span class="icon">
          <fa icon="globe" />
        </span>
        <span>{{ websiteUrl }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
    sponsored: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      primaryFilter: 'directory/primaryFilterField',
    }),
    location() {
      return this.record.locations[0];
    },
    website() {
      return this.location.website || this.record.website || this.record['web-profile'];
    },
    websiteUrl() {
      let hostname;

      if (this.website.indexOf('//') > -1) {
        // eslint-disable-next-line prefer-destructuring
        hostname = this.website.split('/')[2];
      } else {
        // eslint-disable-next-line prefer-destructuring
        hostname = this.website.split('/')[0];
      }
      // eslint-disable-next-line prefer-destructuring
      hostname = hostname.split('?')[0];
      return hostname;
    },
    bio() {
      const bioWithoutHtml = new DOMParser().parseFromString(this.record.bio, 'text/html');
      const bioString = bioWithoutHtml.body.textContent.substring(0, 180) || '';
      return `${bioString}...`;
    },
  },
};
</script>
